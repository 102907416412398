<template>
  <div class="news">
    <div class="banner">
      <div class="container">
        <strong>{{ $t(`LastNews['最新消息']`) }}</strong>
        <p>{{ $t(`LastNews['LATEST NEWS']`) }}</p>
      </div>
    </div>
    <Penel class="active-news" id="events" :title="$t(`LastNews['活动资讯']`)">
      <ul>
        <li v-for="item in 8" :key="item">
          <img src="../assets/image/default.png" alt="" />
          <p>资讯名称资讯名称资讯名称资讯名称资讯名称资讯名称</p>
        </li>
      </ul>
      <div class="buttons">
        <i class="iconfont el-icon-arrow-left" :class="{ disabled: true }"></i>
        <i
          class="iconfont el-icon-arrow-right"
          :class="{ disabled: false }"
        ></i>
      </div>
    </Penel>
    <Penel class="media" id="press" :title="$t(`LastNews['媒体报导']`)">
      <ul>
        <li v-for="item in 4" :key="item">
          <div><img src="../assets/image/default2.png" alt="" /></div>
          <div class="text">
            <h4>新闻名称</h4>
            <p>
              新闻内容简介新闻内容简介新闻内容简介新闻内容简介新闻内容简介新闻内容简介新闻内容简介新闻内容简介新闻内容简介...
            </p>
          </div>
        </li>
      </ul>
      <Pagenation
        @currentChange="handlePageChange"
        :pages="totlePages"
      ></Pagenation>
    </Penel>
  </div>
</template>

<script>
import Pagenation from '@/components/Pagenation'
export default {
  name: 'news',
  components: {
    Pagenation
  },
  data() {
    return {
      totlePages: 20
    }
  },
  methods: {
    handlePageChange(curentPage) {
      console.log(curentPage)
    }
  }
}
</script>

<style lang="less" scoped>
.news {
  .banner {
    height: 550px;
    background: url('../assets/image/news/banner@2x.jpg') no-repeat;
    background-size: cover;
    background-position: center;
    .container {
      padding-top: 230px;
      padding-left: 110px;
      color: #ffffff;
      strong {
        font-size: 48px;
        font-weight: bold;
      }
      p {
        font-size: 21px;
        font-weight: 300;
        color: #ffffff;
        line-height: 3;
      }
    }
    @media screen and (max-width: 992px) {
      display: flex;
      justify-content: center;
      align-items: center;
      .container {
        padding: 80px 18px 0;
        text-align: center;
        strong {
          font-size: 36px;
        }
        p {
          font-size: 16px;
        }
      }
      height: 400px;
    }
  }
  ::v-deep .content {
    padding-top: 38px;
  }
  .active-news {
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 64px;
      li {
        width: 260px;
        margin: 0 10px;
        margin-bottom: 36px;
        img {
          width: 260px;
          height: 156px;
          background: #f7f7f7;
          border-radius: 6px;
        }
        p {
          height: 42px;
          margin-top: 15px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          line-height: 1.5;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
      }
    }
    .buttons {
      text-align: center;
      button {
        width: 30px;
        height: 30px;
      }
      i {
        display: inline-block;
        margin: 0 75px;
        width: 30px;
        height: 30px;
        line-height: 30px;
        background: #e60013;
        border-radius: 50%;
        color: #ffffff;
        cursor: pointer;
      }
      .disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    @media screen and (max-width: 768px) {
      .buttons i {
        margin: 0 50px 20px;
      }
      ul {
        margin: 0 -18px 30px;
        li {
          width: 160px;
          img {
            width: 160px;
            height: 96px;
          }
        }
      }
    }
  }
  .media {
    background: #f7f7f7;
    ul {
      margin-bottom: 80px;
      li {
        display: flex;
        max-width: 1000px;
        box-sizing: border-box;
        height: 180px;
        padding: 30px 0;
        margin: auto;
        border-bottom: 1px solid #eee;
        img {
          margin-right: 30px;
          width: 160px;
          height: 120px;
          background: #b9bac1;
        }
        .text {
          h4 {
            font-size: 16px;
            font-weight: 400;
            color: #30365a;
            margin-bottom: 16px;
            margin-top: 35px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          p {
            overflow: hidden;
            line-height: 1.5;
            height: 63px;
            font-size: 14px;
            color: #70778a;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      ul {
        margin-bottom: 30px;
        li {
          height: unset;
          img {
            width: 120px;
            height: 90px;
            margin-right: 15px;
          }
          .text {
            h4 {
              margin-top: 0px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
